import { LicenseType } from '@models/license';
import {
  Category,
  CompetitionType,
  Gender,
  Modality,
  MovementType,
  PaymentMethod,
  PaymentType,
  ReceiptStatus,
  Scope,
  WeekDay,
  ZoneType,
} from '@models/masters';
import { MatchDateStatus, MatchStatus } from '@models/match';
import { MatchRequestStatus } from '@models/requests';
import { PositionType, ProfileType, Racket, UserGender, UserType } from '@models/user';

export const CATEGORIES: Record<Category, string> = {
  [Category.ALEVIN]: 'Alevín',
  [Category.BENJAMIN]: 'Benjamín',
  [Category.CADETE]: 'Cadete',
  [Category.INFANTIL]: 'Infantil',
  [Category.JUVENIL]: 'Juvenil',
  [Category.SENIOR]: 'Senior',
  [Category.MASTER]: 'Master',
  [Category.MASTER50]: 'Master 50',
};
export const MATCH_STATUSES: Record<MatchStatus, string> = {
  [MatchStatus.FINISHED]: 'Finalizado',
  [MatchStatus.IN_PROGRESS]: 'En progreso',
  [MatchStatus.PENDING]: 'Pendiente',
};

export const MODALITIES: Record<Modality, string> = {
  [Modality.FRONT_BALL]: 'Front Ball',
  [Modality.OLIMPICA]: 'Olímpica',
  [Modality.OLIMPICA_INDIVIDUAL]: 'Olímpica Individual',
  [Modality.PALETA_GOMA]: 'Paleta Goma',
  [Modality.PALETA_GOMA_INDIVIDUAL]: 'Paleta Goma Individual',
  [Modality.PREOLIMPICA]: 'Preolímpica',
  [Modality.PREOLIMPICA_INDIVIDUAL]: 'Preolímpica Individual',
};

export const GENDERS: Record<Gender, string> = {
  [Gender.FEMALE]: 'Femenino',
  [Gender.MALE]: 'Masculino',
  [Gender.MIXED]: 'Mixto',
};

export const USER_GENDERS: Record<UserGender, string> = {
  [UserGender.FEMALE]: 'Femenino',
  [UserGender.MALE]: 'Masculino',
};

export const SCOPES: Record<Scope, string> = {
  [Scope.MIXTAS]: 'Mixtas y Copas',
  [Scope.CAFF]: 'CAFF',
  [Scope.JE]: 'Juegos Escolares',
  [Scope.PELOTA_LENTA]: 'Pelota Lenta',
  [Scope.AFICIONADO]: 'Aficionado',
};

export const COMPETITION_TYPES: Record<CompetitionType, string> = {
  [CompetitionType.CUP]: 'Copa',
  [CompetitionType.LEAGUE]: 'Liga',
  [CompetitionType.JE]: 'Juegos Escolares',
};

export const LICENSE_TYPES: Record<LicenseType, string> = {
  [LicenseType.PLAYER]: 'Jugador',
  [LicenseType.REFEREE]: 'Árbitro',
  [LicenseType.DELEGATE]: 'Delegado',
  [LicenseType.INDEPENDENT]: 'Independiente',
};

export const GENDERS_SELECTOR: { label: string; value: Gender }[] = [
  {
    value: Gender.FEMALE,
    label: GENDERS[Gender.FEMALE],
  },
  {
    value: Gender.MALE,
    label: GENDERS[Gender.MALE],
  },
  {
    value: Gender.MIXED,
    label: GENDERS[Gender.MIXED],
  },
];
export const LICENSE_TYPES_SELECTOR: { label: string; value: LicenseType }[] = [
  {
    value: LicenseType.PLAYER,
    label: LICENSE_TYPES[LicenseType.PLAYER],
  },
  {
    value: LicenseType.REFEREE,
    label: LICENSE_TYPES[LicenseType.REFEREE],
  },
  {
    value: LicenseType.DELEGATE,
    label: LICENSE_TYPES[LicenseType.DELEGATE],
  },
  {
    value: LicenseType.INDEPENDENT,
    label: LICENSE_TYPES[LicenseType.INDEPENDENT],
  },
];

export const CATEGORIES_SELECTOR: { label: string; value: Category | 'ALL' }[] = [
  {
    value: Category.BENJAMIN,
    label: CATEGORIES[Category.BENJAMIN],
  },
  {
    value: Category.ALEVIN,
    label: CATEGORIES[Category.ALEVIN],
  },
  {
    value: Category.INFANTIL,
    label: CATEGORIES[Category.INFANTIL],
  },
  {
    value: Category.CADETE,
    label: CATEGORIES[Category.CADETE],
  },
  {
    value: Category.JUVENIL,
    label: CATEGORIES[Category.JUVENIL],
  },
  {
    value: Category.SENIOR,
    label: CATEGORIES[Category.SENIOR],
  },
  {
    value: Category.MASTER,
    label: CATEGORIES[Category.MASTER],
  },
  {
    value: Category.MASTER50,
    label: CATEGORIES[Category.MASTER50],
  },
];

export const MODALITIES_SELECTOR: { label: string; value: Modality }[] = [
  {
    value: Modality.FRONT_BALL,
    label: MODALITIES[Modality.FRONT_BALL],
  },
  {
    value: Modality.OLIMPICA,
    label: MODALITIES[Modality.OLIMPICA],
  },
  {
    value: Modality.OLIMPICA_INDIVIDUAL,
    label: MODALITIES[Modality.OLIMPICA_INDIVIDUAL],
  },
  {
    value: Modality.PALETA_GOMA,
    label: MODALITIES[Modality.PALETA_GOMA],
  },
  {
    value: Modality.PALETA_GOMA_INDIVIDUAL,
    label: MODALITIES[Modality.PALETA_GOMA_INDIVIDUAL],
  },
  {
    value: Modality.PREOLIMPICA,
    label: MODALITIES[Modality.PREOLIMPICA],
  },
  {
    value: Modality.PREOLIMPICA_INDIVIDUAL,
    label: MODALITIES[Modality.PREOLIMPICA_INDIVIDUAL],
  },
];

export const GENRES_SELECTOR: { label: string; value: Gender }[] = [
  {
    value: Gender.FEMALE,
    label: GENDERS[Gender.FEMALE],
  },
  {
    value: Gender.MALE,
    label: GENDERS[Gender.MALE],
  },
  {
    value: Gender.MIXED,
    label: GENDERS[Gender.MIXED],
  },
];

export const SCOPES_SELECTOR: { label: string; value: Scope }[] = [
  {
    value: Scope.MIXTAS,
    label: SCOPES[Scope.MIXTAS],
  },
  {
    value: Scope.CAFF,
    label: SCOPES[Scope.CAFF],
  },
  {
    value: Scope.JE,
    label: SCOPES[Scope.JE],
  },
  {
    value: Scope.PELOTA_LENTA,
    label: SCOPES[Scope.PELOTA_LENTA],
  },
  {
    value: Scope.AFICIONADO,
    label: SCOPES[Scope.AFICIONADO],
  },
];

export const COMPETITION_TYPES_SELECTOR: { label: string; value: CompetitionType }[] = [
  {
    value: CompetitionType.CUP,
    label: COMPETITION_TYPES[CompetitionType.CUP],
  },
  {
    value: CompetitionType.LEAGUE,
    label: COMPETITION_TYPES[CompetitionType.LEAGUE],
  },
  {
    value: CompetitionType.JE,
    label: COMPETITION_TYPES[CompetitionType.JE],
  },
];

export const PROVINCES: { label: string; value: string }[] = [
  {
    label: 'Alicante',
    value: 'alicante',
  },
  {
    label: 'Castellón',
    value: 'castellon',
  },
  {
    label: 'Valencia',
    value: 'valencia',
  },
  {
    label: 'Álava',
    value: 'alava',
  },
  {
    label: 'Albacete',
    value: 'albacete',
  },
  {
    label: 'Almería',
    value: 'almeria',
  },
  {
    label: 'Asturias',
    value: 'asturias',
  },
  {
    label: 'Ávila',
    value: 'avila',
  },
  {
    label: 'Badajoz',
    value: 'badajoz',
  },
  {
    label: 'Barcelona',
    value: 'barcelona',
  },
  {
    label: 'Burgos',
    value: 'burgos',
  },
  {
    label: 'Cáceres',
    value: 'caceres',
  },
  {
    label: 'Cádiz',
    value: 'cadiz',
  },
  {
    label: 'Cantabria',
    value: 'cantabria',
  },

  {
    label: 'Ceuta',
    value: 'ceuta',
  },
  {
    label: 'Ciudad Real',
    value: 'ciudad_real',
  },
  {
    label: 'Córdoba',
    value: 'cordoba',
  },
  {
    label: 'Cuenca',
    value: 'cuenca',
  },
  {
    label: 'Gerona',
    value: 'gerona',
  },
  {
    label: 'Granada',
    value: 'granada',
  },
  {
    label: 'Guadalajara',
    value: 'guadalajara',
  },
  {
    label: 'Guipúzcoa',
    value: 'guipuzcoa',
  },
  {
    label: 'Huelva',
    value: 'huelva',
  },
  {
    label: 'Huesca',
    value: 'huesca',
  },
  {
    label: 'Islas Baleares',
    value: 'islas_baleares',
  },
  {
    label: 'Jaén',
    value: 'jaen',
  },
  {
    label: 'La Coruña',
    value: 'la_coruna',
  },
  {
    label: 'La Rioja',
    value: 'la_rioja',
  },
  {
    label: 'Las Palmas',
    value: 'las_palmas',
  },
  {
    label: 'León',
    value: 'leon',
  },
  {
    label: 'Lérida',
    value: 'lerida',
  },
  {
    label: 'Lugo',
    value: 'lugo',
  },
  {
    label: 'Madrid',
    value: 'madrid',
  },
  {
    label: 'Málaga',
    value: 'malaga',
  },
  {
    label: 'Melilla',
    value: 'melilla',
  },
  {
    label: 'Murcia',
    value: 'murcia',
  },
  {
    label: 'Navarra',
    value: 'navarra',
  },
  {
    label: 'Orense',
    value: 'orense',
  },
  {
    label: 'Palencia',
    value: 'palencia',
  },
  {
    label: 'Pontevedra',
    value: 'pontevedra',
  },
  {
    label: 'Salamanca',
    value: 'salamanca',
  },
  {
    label: 'Santa Cruz de Tenerife',
    value: 'santa_cruz_de_tenerife',
  },
  {
    label: 'Segovia',
    value: 'segovia',
  },
  {
    label: 'Sevilla',
    value: 'sevilla',
  },
  {
    label: 'Soria',
    value: 'soria',
  },
  {
    label: 'Tarragona',
    value: 'tarragona',
  },
  {
    label: 'Teruel',
    value: 'teruel',
  },
  {
    label: 'Toledo',
    value: 'toledo',
  },
  {
    label: 'Valladolid',
    value: 'valladolid',
  },
  {
    label: 'Vizcaya',
    value: 'vizcaya',
  },
  {
    label: 'Zamora',
    value: 'zamora',
  },
  {
    label: 'Zaragoza',
    value: 'zaragoza',
  },
];

export const USER_TYPES: { label: string; value: UserType }[] = [
  {
    label: 'ADM',
    value: UserType.ADMIN,
  },
  {
    label: 'ARB_COORD',
    value: UserType.REFEREE_COORDINATOR,
  },
];

export const WEEKDAYS: Record<WeekDay, string> = {
  [WeekDay.MONDAY]: 'Lunes',
  [WeekDay.TUESDAY]: 'Martes',
  [WeekDay.WEDNESDAY]: 'Miércoles',
  [WeekDay.THURSDAY]: 'Jueves',
  [WeekDay.FRIDAY]: 'Viernes',
  [WeekDay.SATURDAY]: 'Sábado',
  [WeekDay.SUNDAY]: 'Domingo',
};

export const WEEKDAYS_SELECTOR: { label: string; value: WeekDay }[] = [
  {
    value: WeekDay.MONDAY,
    label: WEEKDAYS[WeekDay.MONDAY],
  },
  {
    value: WeekDay.TUESDAY,
    label: WEEKDAYS[WeekDay.TUESDAY],
  },
  {
    value: WeekDay.WEDNESDAY,
    label: WEEKDAYS[WeekDay.WEDNESDAY],
  },
  {
    value: WeekDay.THURSDAY,
    label: WEEKDAYS[WeekDay.THURSDAY],
  },
  {
    value: WeekDay.FRIDAY,
    label: WEEKDAYS[WeekDay.FRIDAY],
  },
  {
    value: WeekDay.SATURDAY,
    label: WEEKDAYS[WeekDay.SATURDAY],
  },
  {
    value: WeekDay.SUNDAY,
    label: WEEKDAYS[WeekDay.SUNDAY],
  },
];

export const PAYMENT_METHODS: Record<PaymentMethod, string> = {
  [PaymentMethod.CARD]: 'Tarjeta',
  [PaymentMethod.BANK]: 'Transferencia',
};

export const PAYMENT_METHODS_SELECTOR: { label: string; value: PaymentMethod }[] = [
  {
    value: PaymentMethod.CARD,
    label: PAYMENT_METHODS[PaymentMethod.CARD],
  },
  {
    value: PaymentMethod.BANK,
    label: PAYMENT_METHODS[PaymentMethod.BANK],
  },
];

export const RECEIPT_STATUSES: Record<ReceiptStatus, string> = {
  [ReceiptStatus.LINK_CREATED]: 'Enlace creado',
  [ReceiptStatus.PAYMENT_PAID]: 'Pago realizado',
  [ReceiptStatus.SIGNED]: 'Recibo firmado',
  [ReceiptStatus.FFPCV_PAID]: 'Recibo abonado',
};

export const RECEIPT_STATUS_SELECTOR: { label: string; value: ReceiptStatus }[] = [
  {
    value: ReceiptStatus.LINK_CREATED,
    label: RECEIPT_STATUSES[ReceiptStatus.LINK_CREATED],
  },
  {
    value: ReceiptStatus.PAYMENT_PAID,
    label: RECEIPT_STATUSES[ReceiptStatus.PAYMENT_PAID],
  },
  {
    value: ReceiptStatus.SIGNED,
    label: RECEIPT_STATUSES[ReceiptStatus.SIGNED],
  },
  {
    value: ReceiptStatus.FFPCV_PAID,
    label: RECEIPT_STATUSES[ReceiptStatus.FFPCV_PAID],
  },
];
export const PAYMENT_TYPES: Record<PaymentType, string> = {
  [PaymentType.TEAMS]: 'Equipos',
  [PaymentType.PLAYERS]: 'Jugadores',
  [PaymentType.REFEREE]: 'Arbitrajes',
};

export const PAYMENT_TYPES_SELECTOR: { label: string; value: PaymentType }[] = [
  {
    value: PaymentType.TEAMS,
    label: PAYMENT_TYPES[PaymentType.TEAMS],
  },
  {
    value: PaymentType.PLAYERS,
    label: PAYMENT_TYPES[PaymentType.PLAYERS],
  },
];
export const MOVEMENT_TYPES: Record<MovementType, string> = {
  [MovementType.INCOME]: 'Ingreso',
  [MovementType.EXPENDITURE]: 'Gasto',
};

export const MOVEMENT_TYPES_SELECTOR: { label: string; value: MovementType }[] = [
  {
    value: MovementType.INCOME,
    label: MOVEMENT_TYPES[MovementType.INCOME],
  },
  {
    value: MovementType.EXPENDITURE,
    label: MOVEMENT_TYPES[MovementType.EXPENDITURE],
  },
];

export const POSITION_TYPES: Record<PositionType, string> = {
  [PositionType.DELANTERO]: 'Delantero',
  [PositionType.ZAGUERO]: 'Zaguero',
  [PositionType.BOTH]: 'Ambas',
};

export const POSITION_TYPES_SELECTOR: { label: string; value: PositionType }[] = [
  {
    value: PositionType.DELANTERO,
    label: POSITION_TYPES[PositionType.DELANTERO],
  },
  {
    value: PositionType.ZAGUERO,
    label: POSITION_TYPES[PositionType.ZAGUERO],
  },
  {
    value: PositionType.BOTH,
    label: POSITION_TYPES[PositionType.BOTH],
  },
];

export const RACKETS: Record<Racket, string> = {
  [Racket.ARTENGO]: 'Artengo',
  [Racket.BABOLAT]: 'Babolat',
  [Racket.DUNLOP]: 'Dunlop',
  [Racket.HEAD]: 'Head',
  [Racket.MASTERPRO]: 'Masterpro',
  [Racket.PRINCE]: 'Prince',
  [Racket.PROKENNEX]: 'Prokennex',
  [Racket.TECNIFIBRE]: 'Tecnifibre',
  [Racket.URBALL]: 'Urball',
  [Racket.VOLKL]: 'Volkl',
  [Racket.WILSON]: 'Wilson',
  [Racket.YONEX]: 'Yonex',
  [Racket.OTHER]: 'Otra',
};

export const RACKETS_SELECTOR: { label: string; value: Racket }[] = [
  {
    value: Racket.ARTENGO,
    label: RACKETS[Racket.ARTENGO],
  },
  {
    value: Racket.BABOLAT,
    label: RACKETS[Racket.BABOLAT],
  },
  {
    value: Racket.DUNLOP,
    label: RACKETS[Racket.DUNLOP],
  },
  {
    value: Racket.HEAD,
    label: RACKETS[Racket.HEAD],
  },
  {
    value: Racket.MASTERPRO,
    label: RACKETS[Racket.MASTERPRO],
  },
  {
    value: Racket.PRINCE,
    label: RACKETS[Racket.PRINCE],
  },
  {
    value: Racket.PROKENNEX,
    label: RACKETS[Racket.PROKENNEX],
  },
  {
    value: Racket.TECNIFIBRE,
    label: RACKETS[Racket.TECNIFIBRE],
  },
  {
    value: Racket.URBALL,
    label: RACKETS[Racket.URBALL],
  },
  {
    value: Racket.VOLKL,
    label: RACKETS[Racket.VOLKL],
  },
  {
    value: Racket.WILSON,
    label: RACKETS[Racket.WILSON],
  },
  {
    value: Racket.YONEX,
    label: RACKETS[Racket.YONEX],
  },
  {
    value: Racket.OTHER,
    label: RACKETS[Racket.OTHER],
  },
];

export const PROFILES_TYPES: Record<ProfileType, string> = {
  [ProfileType.LEFT]: 'Zurdo',
  [ProfileType.RIGHT]: 'Diestro',
};

export const PROFILES_TYPES_SELECTOR: { label: string; value: ProfileType }[] = [
  {
    value: ProfileType.LEFT,
    label: PROFILES_TYPES[ProfileType.LEFT],
  },
  {
    value: ProfileType.RIGHT,
    label: PROFILES_TYPES[ProfileType.RIGHT],
  },
];

export const ZONE_TYPES: Record<ZoneType, string> = {
  [ZoneType.AUTONOMICO]: 'Autonómico',
  [ZoneType.ALICANTE]: 'Alicante',
  [ZoneType.VALENCIA]: 'Valencia',
  [ZoneType.CASTELLON]: 'Castellón',
  [ZoneType.JECV]: 'Juegos Escolares',
};

export const ZONE_TYPES_SELECTOR: { label: string; value: ZoneType }[] = [
  {
    value: ZoneType.AUTONOMICO,
    label: ZONE_TYPES[ZoneType.AUTONOMICO],
  },
  {
    value: ZoneType.ALICANTE,
    label: ZONE_TYPES[ZoneType.ALICANTE],
  },
  {
    value: ZoneType.VALENCIA,
    label: ZONE_TYPES[ZoneType.VALENCIA],
  },
  {
    value: ZoneType.CASTELLON,
    label: ZONE_TYPES[ZoneType.CASTELLON],
  },
  {
    value: ZoneType.JECV,
    label: ZONE_TYPES[ZoneType.JECV],
  },
];

export const MATCH_REQUESTS_STATUSES: Record<MatchRequestStatus, string> = {
  [MatchRequestStatus.PENDING]: 'Pendiente',
  [MatchRequestStatus.ACCEPTED]: 'Aceptado',
  [MatchRequestStatus.REJECTED]: 'Rechazado',
};

export const MATCH_REQUESTS_STATUSES_SELECTOR: { label: string; value: MatchRequestStatus }[] = [
  {
    value: MatchRequestStatus.PENDING,
    label: MATCH_REQUESTS_STATUSES[MatchRequestStatus.PENDING],
  },
  {
    value: MatchRequestStatus.ACCEPTED,
    label: MATCH_REQUESTS_STATUSES[MatchRequestStatus.ACCEPTED],
  },
  {
    value: MatchRequestStatus.REJECTED,
    label: MATCH_REQUESTS_STATUSES[MatchRequestStatus.REJECTED],
  },
];

export const MATCH_REQUESTS_DATE_STATUSES: Record<MatchDateStatus, string> = {
  [MatchDateStatus.ADVANCED]: 'Adelantameinto',
  [MatchDateStatus.PERMUTED]: 'Permutado',
  [MatchDateStatus.POSTPONED]: 'Aplazado',
};

export const MATCH_REQUESTS_DATE_STATUSES_SELECTOR: { label: string; value: MatchDateStatus }[] = [
  {
    value: MatchDateStatus.ADVANCED,
    label: MATCH_REQUESTS_DATE_STATUSES[MatchDateStatus.ADVANCED],
  },
  {
    value: MatchDateStatus.PERMUTED,
    label: MATCH_REQUESTS_DATE_STATUSES[MatchDateStatus.PERMUTED],
  },
  {
    value: MatchDateStatus.POSTPONED,
    label: MATCH_REQUESTS_DATE_STATUSES[MatchDateStatus.POSTPONED],
  },
];
