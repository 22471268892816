import { Entity } from './common';
import { MatchDateStatus } from './match';

export interface MatchRequest extends Entity {
  type: MatchDateStatus;
  newDate: Date;
  comments: string;
  matchId: string;
  fromId: string;
  toId: string;
  status: MatchRequestStatus;
  seasonYear: string; // Year
}

export enum MatchRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}
